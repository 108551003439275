import React from "react";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../../components/Dashboards/style";
import {checkAuth} from "../../../helpers/auth";
import Results from '../../../components/DashboardResults';
import { ButtonGroup, Button } from "@material-ui/core";
import axios from "axios";

import ReportsFiltering from "../../../components/ReportsFiltering";
import moment from 'moment';
import ScrollTopLeft from "../../../components/Miscellaneous/scrollTopLeft";
import { navigate } from 'gatsby';

class ClientSubmissionAggregate extends React.Component {
    results = React.createRef();
    state = {
        config: null,
        selectedDimensions: {
            dimension1: 'Co-Reg Campaign',
            dimension2: 'Client',
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
        },
        additionalFilters: {

        }
    }

    componentDidMount() {
        checkAuth('media-planner');
        axios.get(`${process.env.SERVER_ENDPOINT}/reportsConfig/coreg`).then(response => {
            this.setState({
                ...this.state,
                config: response.data
            })
        })
    }

    onSelect(selected, additionalFilters) {
        this.setState({
            ...this.state,
            selectedDimensions: selected,
            additionalFilters: additionalFilters
        }, () => {
            this.results.current.loadData();
        })
    }

    getDimensionParams() {
        let str = '';
        for(let i in this.state.selectedDimensions) {
            if(typeof(this.state.selectedDimensions[i]) !== 'undefined') {
                if(typeof(this.state.selectedDimensions[i]) === 'string') {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i]}`;
                } else {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i].join(`&${i}=`)}`;
                }
            }
        }
        return str;
    }

    getFilterParams() {
        let str = '';
        if(Object.keys(this.state.additionalFilters).length > 0) {
            for(let i in this.state.additionalFilters) {
                str += `&${encodeURIComponent(i)}=${this.state.additionalFilters[i]}`;
            }
        }
        return str;
    }

    getColumns() {
        let cols = [...this.state.config.columns];
        cols.unshift(this.state.selectedDimensions.dimension4);
        cols.unshift(this.state.selectedDimensions.dimension3);
        cols.unshift(this.state.selectedDimensions.dimension2);
        cols.unshift(this.state.selectedDimensions.dimension1);
        return cols;
    }

    groupBy(value) {
        navigate(`/media-planner/internal-activity/${value}`);
    }

    render() {
        let cols = [];
        if(this.state.config !== null) {
            cols = this.getColumns();
        }
        let groupingCols = [];
        [this.state.selectedDimensions.dimension1, 
            this.state.selectedDimensions.dimension2,
            this.state.selectedDimensions.dimension3, 
            this.state.selectedDimensions.dimension4
        ].map(d => d ? groupingCols.push(d) : false)
        return (
            <Layout>
                <SEO title="Welcome"/>
                <Grid container style={style.mainContainer}>
                    <Grid container justify="center" spacing={2}>
                        {this.state.config !== null ? 
                            <div className='dashboardGroup__switch' style={style.tabContainer}>
                                <ButtonGroup variant="contained" aria-label="contained primary button group">
                                    <Button className={'dashboardGroup__switch-button'} onClick={() => this.groupBy('cto')}>CTO</Button>
                                    <Button className={'dashboardGroup__switch-button active'} onClick={() => this.groupBy('coreg')}>CO-REG</Button>
                                    <Button className={'dashboardGroup__switch-button'} onClick={() => this.groupBy('submissions')}>CLIENT SUBMISSIONS</Button>
                                </ButtonGroup>

                                <Results 
                                    ref={this.results}
                                    sort={{
                                    column: cols[0],
                                    asc: false
                                    
                                }} 
                                drillDownFormatting={this.state.config.drillDownFormatting}
                                groupingOptions={{
                                    cols: groupingCols,
                                    startingRow: 0
                                }}
                                columns={cols} 
                                drillDownOptions={{
                                    columns: this.state.config.drillDownColumns,
                                    queryParams: this.getFilterParams() + this.getDimensionParams()
                                }}
                                drillDownFormatting={this.state.config.drillDownFormatting}
                                url={process.env.SERVER_ENDPOINT + `/reports/clientSubmissionAggregate/coreg?${this.getDimensionParams()}${this.getFilterParams()}`}
                                exportCSVConfig={{
                                    name:'client-submissions',
                                    url: process.env.SERVER_ENDPOINT + `/reports/clientSubmissionAggregate/coreg?${this.getDimensionParams()}${this.getFilterParams()}`
                                }}
                                columnsFormat={this.state.config.columnsFormat}
                                filters={{
                                    selected: this.state.selectedDimensions, 
                                    additionalFilters: this.state.additionalFilters
                                }}
                                >
                                
                                <h3>{this.state.config.title}</h3>
                                <ReportsFiltering
                                    onSelect={this.onSelect.bind(this)}
                                    selected={{
                                        dimension1: 'Co-Reg Campaign',
                                        dimension2: 'Client',
                                        startDate: this.state.selectedDimensions.startDate,
                                        endDate: this.state.selectedDimensions.endDate
                                    }}
                                    config={this.state.config}
                                    type="internal-activity@coreg"
                                />
                                </Results>
                                <ScrollTopLeft />
                            </div>
                        : null}
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default ClientSubmissionAggregate;
